import { graphql, Link, navigate } from 'gatsby';
import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import tw, { css, styled } from 'twin.macro';

import Play from '../assets/icons/play.svg';
import { SliceWrapper, SliceWrapperProps } from '../core-ui/SliceWrapper';
import { RichText, Text, TextType } from '../core-ui/Text';
import { Wrapper } from '../core-ui/Wrapper';
import { getYoutubeVideoId } from '../utils/getYoutubeVideoId';
import { ContentRowWithVideoFragment } from '../__generated__/types';
import { Button } from './Button';
import { TextContentRow } from './TextContentRow';

type Bg = 'gray' | 'black' | 'white';

type ContentPosition = 'left' | 'right';

type MediaWrapperBaseProps = { isMediaFullScreen: boolean };

const TextContentRowWrapper = styled.div<
  MediaWrapperBaseProps & { textOnly: boolean }
>`
  ${({ textOnly }) => (textOnly ? tw`w-full lg:w-9/12` : tw`w-full lg:w-5/12`)}
  ${({ isMediaFullScreen }) =>
    isMediaFullScreen
      ? tw`py-40 sm:py-80`
      : tw`pt-0 pb-24 sm:pb-40 lg:pb-0 flex items-center`}

 @media only screen and (min-width: 1240px) {
    min-width: 620px;
    margin-left: auto;
    ${tw`pr-36`}
  }
`;

const VideoContentRowWrapper = styled.div<MediaWrapperBaseProps>`
  ${tw`w-full lg:w-6/12 lg:h-auto`}
  ${({ isMediaFullScreen }) =>
    isMediaFullScreen ? tw`absolute lg:relative` : tw`relative`}
  top: 100%;
`;

const StyledImageWrapper = styled.div<MediaWrapperBaseProps>`
  -webkit-box-shadow: -2px 9px 28px 0px rgba(0, 0, 0, 0.58);
  -moz-box-shadow: -2px 9px 28px 0px rgba(0, 0, 0, 0.58);
  box-shadow: -2px 9px 28px 0px rgba(0, 0, 0, 0.58);

  width: calc(100% - 48px);
  @media only screen and (min-width: 640px) {
    width: calc(100% - 4rem);
  }
  @media only screen and (min-width: 1024px) {
    width: 100%;
  }
  ${({ isMediaFullScreen }) => {
    if (!isMediaFullScreen) {
      return css`
        width: 100%;
        height: 100%;
        top: 0;
        @media only screen and (min-width: 640px) {
          width: 100%;
        }
        @media only screen and (min-width: 1024px) {
          width: 100%;
          height: 100%;
        }
        @media only screen and (min-width: 1240px) {
          max-height: 563px;
        }
      `;
    }
  }}
  &:after {
    -webkit-box-shadow: inset -1px -90px 121px -24px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: inset -1px -90px 121px -24px rgba(0, 0, 0, 0.75);
    box-shadow: inset -1px -90px 121px -24px rgba(0, 0, 0, 0.75);

    @media only screen and (min-width: 768px) {
      -webkit-box-shadow: inset -4px -180px 200px 12px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: inset -4px -180px 200px 12px rgba(0, 0, 0, 0.75);
      box-shadow: inset -4px -180px 200px 12px rgba(0, 0, 0, 0.75);
    }
    content: '';
    display: block;
    position: absolute;

    ${({ isMediaFullScreen }) => {
      if (isMediaFullScreen) {
        return css`
          top: 0;
          width: calc(100% - 48px);
          height: 100%;
          @media only screen and (min-width: 640px) {
            width: calc(100% - 4rem);
          }
          @media only screen and (min-width: 1024px) {
            top: -10%;
            width: 100%;
            height: 110%;
          }
        `;
      }
      return css`
        width: 100%;
        height: 100%;
        top: 0;
        max-height: 563px;
      `;
    }}
  }
`;

const StyledImage = styled.img<MediaWrapperBaseProps>`
  object-fit: cover;
  ${({ isMediaFullScreen }) => {
    return isMediaFullScreen
      ? css`
          top: 0;
          width: 100%;
          position: relative;
          @media only screen and (min-width: 640px) {
            height: 110%;
          }
          @media only screen and (min-width: 1024px) {
            position: absolute;
            top: -10%;
            width: 100%;
          }
        `
      : css`
          width: 100%;
          height: auto;
          @media only screen and (min-width: 1240px) {
            height: max-content;
            max-width: 810px;
            max-height: 563px;
          }
        `;
  }}
`;

const VideoCaption = styled(Text)`
  ${tw`pl-8 pb-8 md:pb-48 md:pl-60 bottom-0 absolute w-8/12 text-white`}
  z-index: 3;
  @media only screen and (min-width: 768px) {
    line-height: 1;
  }
`;

const Container = styled(Wrapper)<{
  isLeft: boolean;
  isMediaFullScreen: boolean;
}>`
  ${tw`justify-between flex-wrap lg:flex-no-wrap relative`}

  ${({ isMediaFullScreen }) =>
    isMediaFullScreen
      ? tw`max-w-1440`
      : tw`pt-60 md:pt-120 max-w-screen-xl lg:pr-0`}
  ${({ isLeft, isMediaFullScreen }) =>
    isMediaFullScreen && (isLeft ? tw`lg:pr-0 ` : tw`lg:pl-0`)}

`;

const PlayButtonContainer = styled.button<MediaWrapperBaseProps>`
  position: absolute;
  z-index: 3;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  width: calc(100% - 48px);
  position: absolute;
  top: 0;
  @media only screen and (min-width: 640px) {
    width: calc(100% - 4rem);
  }
  @media only screen and (min-width: 1024px) {
    top: -10%;
    width: 100%;
  }
  ${({ isMediaFullScreen }) =>
    !isMediaFullScreen &&
    css`
      top: 0;
      max-height: 563px;
      width: 100%;
      @media only screen and (min-width: 640px) {
        width: 100%;
      }
      @media only screen and (min-width: 1024px) {
        top: 0;
        width: 100%;
      }
    `}
`;

type Props = {
  data: ContentRowWithVideoFragment;
  nextSliceBg?: SliceWrapperProps['bg'];
} & Pick<SliceWrapperProps, 'last'>;

const ModalVideoOption = {
  autoplay: 1,
};

// TODO: Create mobile

export const ContentRowWithVideo: React.FC<Props> = ({
  data: { primary },
  last,
  nextSliceBg,
}) => {
  let [isPlayingVideo, setIsPlayingVideo] = useState(false);
  if (!primary) {
    return null;
  }
  let {
    content_position,
    use_decorator,
    section_title,
    content_title,
    content,
    section_video,
    action_button_title,
    action_button_position,
    section_video_description,
    section_media_size,
    link_action_button,
    section_id,
  } = primary;
  let isTextPositionLeft = content_position === 'left';
  let isMediaFullScreen = section_media_size !== 'within container';
  let isActionButtonBelowText = action_button_position === 'below text content';
  let contentOrder = isTextPositionLeft ? ['text', 'video'] : ['video', 'text'];
  let onClose = () => setIsPlayingVideo(false);
  let playVideo = () => setIsPlayingVideo(true);
  return (
    <SliceWrapper
      bg={nextSliceBg}
      css={tw`relative`}
      id={section_id || undefined}
    >
      <SliceWrapper
        bg={primary?.background_color as Bg}
        last={last}
        css={css`
          padding-bottom: 30%;
          margin-bottom: ${isMediaFullScreen ? 30 : 0}%;
          @media only screen and (min-width: 640px) {
            padding-bottom: 30%;
            margin-bottom: ${isMediaFullScreen ? 30 : 0}%;
          }
          @media only screen and (min-width: 1024px) {
            padding-bottom: 0;
            margin-bottom: 0;
          }
          ${last && tw`pb-250 sm:pb-350`}
        `}
      >
        <ModalVideo
          channel="youtube"
          isOpen={isPlayingVideo}
          youtube={ModalVideoOption}
          videoId={getYoutubeVideoId(section_video?.embed_url ?? '')}
          onClose={onClose}
        />
        <Container isLeft={true} isMediaFullScreen={isMediaFullScreen} row>
          {contentOrder.map((type) => {
            if (type === 'text') {
              return (
                <TextContentRowWrapper
                  textOnly={!section_video}
                  isMediaFullScreen={isMediaFullScreen}
                  key="textContentRow"
                >
                  <TextContentRow
                    key="textContentRow"
                    useDecorator={!!use_decorator}
                    sectionTitle={section_title}
                    contentTitle={content_title?.text}
                    content={content?.richText}
                    actionButtonTitle={
                      isActionButtonBelowText && action_button_title
                        ? action_button_title
                        : undefined
                    }
                    actionButtonTheme={
                      primary?.background_color === 'black' ? 'dark' : 'light'
                    }
                    onButtonClick={() => {
                      if (
                        link_action_button &&
                        link_action_button.document?.__typename ===
                          'PrismicPage'
                      ) {
                        navigate(`/${link_action_button?.document.uid ?? ''}`);
                      }
                    }}
                  />
                </TextContentRowWrapper>
              );
            }
            return (
              <VideoContentRowWrapper
                key="videoContentRow"
                isMediaFullScreen={isMediaFullScreen}
              >
                <StyledImageWrapper isMediaFullScreen={isMediaFullScreen}>
                  <StyledImage
                    src={section_video?.thumbnail_url?.replace(
                      'hqdefault',
                      'maxresdefault',
                    )}
                    isMediaFullScreen={isMediaFullScreen}
                  />
                  <div css={tw`w-6/12`}>
                    <VideoCaption type={TextType.subtitle}>
                      {section_video_description?.text}
                    </VideoCaption>
                  </div>
                  <PlayButtonContainer
                    aria-label="Video Play Button"
                    onClick={playVideo}
                    isMediaFullScreen={isMediaFullScreen}
                  >
                    <div>
                      <Play
                        css={css`
                          width: 36px;
                          @media only screen and (min-width: 1240px) {
                            width: unset;
                          }
                        `}
                      />
                    </div>
                  </PlayButtonContainer>
                </StyledImageWrapper>
              </VideoContentRowWrapper>
            );
          })}
        </Container>
        {isActionButtonBelowText || !action_button_title
          ? null
          : action_button_title != null && (
              <Wrapper css={tw`mt-80 flex justify-center`}>
                <Link
                  to={
                    link_action_button &&
                    link_action_button.document?.__typename === 'PrismicPage'
                      ? `/${link_action_button?.document?.uid ?? ''}`
                      : ''
                  }
                  css={tw`max-w-full`}
                >
                  <Button
                    label={action_button_title.toUpperCase()}
                    theme={
                      primary?.background_color === 'black' ? 'dark' : 'light'
                    }
                  />
                </Link>
              </Wrapper>
            )}
      </SliceWrapper>
    </SliceWrapper>
  );
};

export const query = graphql`
  fragment ContentRowWithVideo on PrismicPageDataBodyCustomContentVideo {
    __typename
    slice_type
    primary {
      section_id
      background_color
      content {
        text
        html
        richText
      }
      content_alignment
      content_position
      content_title {
        text
        html
        richText
      }
      display_jargon
      section_title
      use_decorator
      section_video {
        embed_url
        thumbnail_url
      }
      section_video_description {
        text
        html
        richText
      }
      section_media_size
      link_action_button {
        ...LinkButtonToOtherPage
      }
      action_button_title
      action_button_position
      section_media_size
    }
  }
`;
